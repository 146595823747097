<template>
	<div class="home">
		<meta name="keywords" content="vooBank" />
		<div class="banner">
			<div class="title">Digital Asset Card</div>
			<div class="font">
				VooBank Digital Asset Card offers users a seamless experience in using both cryptocurrencies
				and fiat currencies globally. This innovative card is a result of collaboration between
				MarsVerse and leading international financial institutions (e.g. Visa). Users can now enjoy
				the convenience of using their cryptocurrencies for payments, withdrawals, or online
				shopping worldwide, while also instantly converting their digital assets into major fiat
				currencies.
				<br />
				Both card types of VooBank Digital Asset Card support major cryptocurrencies, e.g. BTC, ETH,
				USDT, USDC and more.
			</div>
			<div class="bottom_box">
				<div class="item_one">
					<div class="item_one_content">
						{{ $t('home.list0') }}
					</div>
					<img
						src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmThwDj7EPM2nAzLuHFHzsxZbfKsG5oefShkkr1794TfLa"
						alt=""
					/>
				</div>
				<div class="item" v-for="(item, index) in arr" :key="index">
					<img :src="item.url" alt="" class="top" />
					<div class="font1">{{ item.title }}</div>
					<div class="font2">{{ item.font }}</div>
				</div>
			</div>
		</div>
		<div class="case">
			<div class="case_title">
				<img
					src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmafcWSsa3GazhCLqr2yvWhcHDHZu8GrXkujkr4EWMMgwc"
					alt=""
				/>
				<span>
					{{ $t('home.case_title') }}
				</span>
			</div>
			<div class="case_item">
				<div class="case_item_title">
					{{ $t('home.case1') }}
				</div>
				<img
					src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmdL5hRcTcVFQFoyh6Gchod4sEhkCH3RnzTCAUMXbA3EJN"
					alt=""
				/>
			</div>
			<div class="case_item">
				<img
					src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmerJaLbeR6ZanhaEYCeaneu4EWsbcaYsCzTjNbRKzvdrx"
					alt=""
				/>
				<div class="case_item_title">
					{{ $t('home.case2') }}
				</div>
			</div>
			<div class="case_item">
				<div class="case_item_title">
					{{ $t('home.case3') }}
				</div>
				<img
					src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmYmdN9aXmZgGL1PknNqiXJkasdxyeoqsR2dE5Dmoc8tLH"
					alt=""
				/>
			</div>
			<div class="case_item">
				<img
					src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmRnMLsjHjM6fDWcY3qZHpsxX4Stu6W6gqqjcXBMhsqWs7"
					alt=""
				/>
				<div class="case_item_title">
					{{ $t('home.case4') }}
				</div>
			</div>
		</div>

		<div class="fees">
			<div class="fees_title">
				<img
					src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmafcWSsa3GazhCLqr2yvWhcHDHZu8GrXkujkr4EWMMgwc"
					alt=""
				/>
				<span>
					{{ $t('home.fees_title') }}
				</span>
			</div>
			<div class="fees_box">
				<div class="fees_top">
					<div
						@click="setActive(item)"
						v-for="(item, index) in feesArr"
						:key="index"
						class="fees_button"
						:class="{
							fees_button_active: item.active,
							fees_button2: item.name == 'Physical Card' ? true : false,
						}"
					>
						{{ item.name }}
					</div>
				</div>
				<div class="fees_content" v-if="feesArr[0].active === true">
					<img
						src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmNadag77LB2oxfnYMhJS7jofMZTiGckjUkY6e1bAaEimZ"
						alt=""
					/>
					<ul>
						<li>Card Ordering Fee: $0</li>
						<li>Monthly Maintenance Fee: $0 - $5</li>
						<li>Deposit Fee: $0</li>
						<li>Withdrawal Fee: $10</li>
						<li>Cross-Border Payment Fee: 1% - 3% of the transaction amount</li>
						<li>Currency Conversion Fee: 2% - 5% above the market rate</li>
					</ul>
				</div>
				<div class="fees_content fees_content_active" v-else>
					<ul>
						<li>Card Ordering Fee (including shipment): $200</li>
						<li>Monthly Maintenance Fee: $2 - $10</li>
						<li>Deposit Fee: $0</li>
						<li>Cash Withdrawal Fee: $20</li>
						<li>Cross-Border Payment Fee: 1% - 2% of the transaction amount</li>
						<li>Currency Conversion Fee: 1% - 3% above the market rate</li>
					</ul>
					<img
						src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmcsXKkTfrEhzaVhrCSDfn7ESc6MwkrUjpdLxWqDa9YEfL"
						alt=""
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { CountTo } from 'vue3-count-to';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
const { t, tm } = useI18n();
const router = useRouter();

let arr = ref([
	{
		url: `https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmaseLgkpKAn6bZHUS7PbWNk7HAkwB6X5pevLty8urpef9`,
		title: t('home.list1.title'),
		font: t('home.list1.font'),
	},
	{
		url: `https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmTWKiANmtqUTHitPxV7Hv3pMGcqqDCCLzMxB8RmRhPP6D`,
		title: t('home.list2.title'),
		font: t('home.list2.font'),
	},
	{
		url: `https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmW1jc2qhS7vnn4N8c5kGTXmMB5KxzWJ7GhLnBr8Sh7JAV`,
		title: t('home.list3.title'),
		font: t('home.list3.font'),
	},
	{
		url: `https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmXfERRJyZH9LBZWfDHNtMcoCMLnnv92Ry4azDngr3Z1ef`,
		title: t('home.list4.title'),
		font: t('home.list4.font'),
	},
	{
		url: `https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmRx4U8xiGELfrp6oW5SybxfV7VAMy8Kt2xixh5qaX5w3d`,
		title: t('home.list5.title'),
		font: t('home.list5.font'),
	},
]);

let feesArr = reactive([
	{
		name: 'Virtual Card',
		active: true,
	},
	{
		name: 'Physical Card',
		active: false,
	},
]);

const numberbox = ref(null);
const numberFlag = ref(false);

const setActive = (item) => {
	feesArr.map((items) => {
		items.active = false;
		if (item.name == items.name) {
			items.active = true;
		}
	});
};
</script>

<style scoped lang="scss">
/*
	进入和离开动画可以使用不同
	持续时间和速度曲线。
	*/
.slide-fade-enter-active {
	transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
	transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	transform: translatey(20px);
	opacity: 0;
}
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.home {
		width: 100%;
		color: #ffffff;
		background: #000;
		padding-bottom: 214px;
		.banner {
			width: 100%;
			padding: 0 360px;
			background: url('https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmXZz9xYnVpwm6kjXdfn1wquG9Yda5aAkHyG89NYRYn6Ns')
				no-repeat;
			background-size: 1930px 1388px;
			.title {
				padding-top: 100px;
				font-size: 50px;
				font-weight: 500;
				color: rgba(202, 130, 50, 1);
			}
			.font {
				margin-top: 27px;
				width: 597px;
				font-size: 22px;
				font-weight: 400;
				line-height: 30px;
			}
			.bottom_box {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin-top: 205px;

				.item_one {
					width: 380px;
					height: 320px;
					display: inline-flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					.item_one_content {
						width: 325px;
						margin-bottom: 23px;
						font-weight: 600;
						font-size: 30px;
						line-height: 36px;
					}
					img {
						width: 325px;
						height: 7px;
					}
				}

				.item {
					width: 380px;
					height: 320px;
					border: 1px solid rgba(202, 130, 50, 1);
					margin-bottom: 43px;
					padding: 30px 0 0px 40px;
					border-radius: 2px;

					.top {
						width: 67px;
						margin-bottom: 20px;
					}

					.font1 {
						font-size: 24px;
						font-weight: 600;
						margin-bottom: 10px;
						line-height: 30px;
					}

					.font2 {
						font-size: 12px;
						font-weight: 300;
						width: 308px;
						line-height: 18px;
					}
				}
			}
		}

		.case {
			width: 100%;
			padding: 0 360px;
			.case_title {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 150px;
				font-weight: 600;
				font-size: 56px;
				line-height: 70px;
				margin-bottom: 76px;
				img {
					width: 31px;
					height: 31px;
					margin-right: 6px;
				}
			}
			.case_item {
				width: 100%;
				height: 326px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 70px;
				.case_item_title {
					width: 500px;
					font-size: 44px;
					font-weight: 700;
				}
				img {
					height: 326px;
					border-radius: 16px;
				}
			}
		}

		.fees {
			width: 100%;
			padding: 0 360px;
			.fees_title {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 150px;
				font-weight: 600;
				font-size: 56px;
				line-height: 70px;
				margin-bottom: 76px;
				img {
					width: 31px;
					height: 31px;
					margin-right: 6px;
				}
			}
			.fees_box {
				border-radius: 30px;
				width: 100%;
				height: 593px;
				background: rgba(39, 37, 40, 1);
				.fees_top {
					width: 100%;
					height: 100px;
					border-radius: 30px 30px 0 0;
					display: flex;
					.fees_button_active {
						color: rgba(202, 130, 50, 1) !important;
					}
					.fees_button {
						width: 100%;
						height: 100px;
						border-radius: 30px 30px 0 0;
						background: rgba(39, 37, 40, 1);
						color: rgba(255, 255, 255, 0.6);
						font-weight: 600;
						font-size: 40px;
						text-align: center;
						line-height: 100px;
					}
					.fees_button2 {
						background: rgba(24, 23, 24, 1);
					}
				}
				.fees_content {
					width: 100%;
					height: 493px;
					background: rgba(39, 37, 40, 1);
					border-radius: 0 0 30px 30px;
					padding: 64px 20px 0;
					display: flex;
					img {
						width: 586px;
						height: 351px;
						background: gray;
						border-radius: 28px;
					}
					ul {
						margin-left: 49px !important;
						margin-top: 7px !important;
						list-style: none;
						li {
							font-size: 22px;
							font-weight: 400;
							line-height: 28px;
						}
					}
				}
				.fees_content_active {
					background: rgba(24, 23, 24, 1);
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.home {
		width: 100%;
		color: #ffffff;
		background: #000;
		padding-bottom: 100px;
		.banner {
			width: 100%;
			padding: 0 30px;
			background: url('https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmVtMeE3oeyvhdTkcQDRzuQrzXF5TpCREz7vvQNxbfSHMk')
				no-repeat;
			background-size: 375px auto;
			text-align: center;
			.title {
				padding-top: 380px;
				font-size: 28px;
				font-weight: 500;
				color: rgba(202, 130, 50, 1);
			}
			.font {
				margin-top: 13px;
				width: 296px;
				font-size: 22px;
				font-weight: 400;
				line-height: 30px;
			}
			.bottom_box {
				width: 100%;
				display: flex;
				flex-direction: column;
				// flex-wrap: wrap;
				// justify-content: space-between;
				margin-top: 102px;

				.item_one {
					// width: 303px;
					// height: 232px;
					display: inline-flex;
					flex-direction: column-reverse;
					justify-content: center;
					align-items: center;
					padding-bottom: 30px;
					.item_one_content {
						width: 264px;
						font-weight: 600;
						font-size: 24px;
						line-height: 30px;
						text-align: center;
					}
					img {
						width: 33px;
						height: 7px;
						margin-bottom: 10px;
					}
				}

				.item {
					// width: 303px;
					height: 252px;
					border: 1px solid rgba(202, 130, 50, 1);
					margin-bottom: 10px;
					padding: 20px 30px 0px;
					border-radius: 2px;
					text-align: left;

					.top {
						width: 52px;
						margin-bottom: 15px;
					}

					.font1 {
						font-size: 18px;
						font-weight: 600;
						margin-bottom: 10px;
						line-height: 22.5px;
					}

					.font2 {
						font-size: 10px;
						font-weight: 300;
						width: 264px;
						// margin: 0 auto;
						line-height: 14px;
					}
				}
			}
		}

		.case {
			width: 100%;
			padding: 0 30px;
			.case_title {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 50px;
				font-weight: 600;
				font-size: 28px;
				line-height: 70px;
				margin-bottom: 76px;
				img {
					width: 15px;
					height: 15px;
					margin-right: 3px;
				}
			}
			.case_item {
				width: 100%;
				// height: 163px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				// align-items: center;
				// text-align: center;
				.case_item_title {
					width: 283px;
					font-size: 24px;
					font-weight: 700;
					margin-bottom: 30px;
				}
				img {
					width: 315px;
					height: 185px;
					border-radius: 16px;
					margin-bottom: 15px;
				}
			}
			.case_item:nth-child(even) {
				flex-direction: column-reverse;
			}
		}

		.fees {
			width: 100%;
			padding: 0 30px;
			.fees_title {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 100px;
				font-weight: 600;
				font-size: 28px;
				line-height: 30px;
				margin-bottom: 38px;
				img {
					width: 31px;
					height: 31px;
					margin-right: 6px;
				}
			}
			.fees_box {
				border-radius: 30px;
				width: 100%;
				height: 593px;
				background: rgba(39, 37, 40, 1);
				.fees_top {
					width: 100%;
					height: 64px;
					border-radius: 30px 0 0 0;
					display: flex;
					.fees_button_active {
						color: rgba(202, 130, 50, 1) !important;
					}
					.fees_button {
						width: 100%;
						height: 64px;
						border-radius: 30px 0 0 0;
						background: rgba(39, 37, 40, 1);
						color: rgba(255, 255, 255, 0.6);
						font-weight: 600;
						font-size: 20px;
						text-align: center;
						line-height: 64px;
					}
					.fees_button2 {
						background: rgba(24, 23, 24, 1);
						border-radius: 0 30px 0 0;
					}
				}
				.fees_content {
					width: 100%;
					// height: 493px;
					background: rgba(39, 37, 40, 1);
					border-radius: 0 0 30px 30px;
					padding: 24px 10px 0;
					display: flex;
					flex-direction: column;
					img {
						width: 293px;
						height: 175px;
						background: gray;
						border-radius: 28px;
						margin-bottom: 20px;
					}
					ul {
						margin-left: 25px !important;
						margin-top: 7px !important;
						list-style: none;
						li {
							font-size: 16px;
							font-weight: 400;
							line-height: 20px;
						}
					}
				}
				.fees_content_active {
					// height: 100%;
					flex-direction: column-reverse;
					padding-bottom: 25px;
					background: rgba(24, 23, 24, 1);
				}
			}
		}
	}
}
</style>
